exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abo-js": () => import("./../../../src/pages/abo.js" /* webpackChunkName: "component---src-pages-abo-js" */),
  "component---src-pages-ausgabe-js": () => import("./../../../src/pages/ausgabe.js" /* webpackChunkName: "component---src-pages-ausgabe-js" */),
  "component---src-pages-ausgabe-kaufen-js": () => import("./../../../src/pages/ausgabe-kaufen.js" /* webpackChunkName: "component---src-pages-ausgabe-kaufen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schwerpunkt-js": () => import("./../../../src/pages/schwerpunkt.js" /* webpackChunkName: "component---src-pages-schwerpunkt-js" */),
  "component---src-pages-spenden-js": () => import("./../../../src/pages/spenden.js" /* webpackChunkName: "component---src-pages-spenden-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-edition-js": () => import("./../../../src/templates/edition.js" /* webpackChunkName: "component---src-templates-edition-js" */),
  "component---src-templates-focus-js": () => import("./../../../src/templates/focus.js" /* webpackChunkName: "component---src-templates-focus-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-ressort-js": () => import("./../../../src/templates/ressort.js" /* webpackChunkName: "component---src-templates-ressort-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

